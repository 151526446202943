import * as constants from '@/store/constants.js'
import { mutations, state } from '@/store'
import { sendRequest } from '@/lib/gms/api.js'

export const stateSetting = { 
    config: { }
}

export const mutationsSetting = {
    readSetting( ) {
        mutations.setDataStateConfig(constants.DATA_STATE_PREPARING)

        sendRequest(
            '/api/read-setting',
            { })
            .then(response => {
                //console.log(response)
      
                state.config = response.data
                
                // Nastaví document.title
                document.title = state.config.application.title

                mutations.setDataStateConfig(constants.DATA_STATE_READY)
            })
            .catch(errors => {
                console.log('readConfig.errors', errors)

                mutations.setDataStateConfig(constants.DATA_STATE_NOT_READY)
            })
    }
}