import * as constants from '@/store/constants.js'
import { /*mutations,*/ state } from '@/store'

export const stateDataState = {
    // Aktuální stav dat
    data_state: {
        config: {
            state: constants.DATA_STATE_NOT_READY
        },

        host: {
            state: constants.DATA_STATE_NOT_READY
        },

        rezervace: {
            state: constants.DATA_STATE_NOT_READY
        },

        users: {
            state: constants.DATA_STATE_NOT_READY
        }
    }
}

export const mutationsDataState = {
    setDataStateConfig(newState) {
        state.data_state.config.state = newState
    },

    setDataStateHost(newState) {
        state.data_state.host.state = newState
    },

    setDataStateRezervace(newState) {
        state.data_state.rezervace.state = newState
    },

    setDataStateUsers(newState) {
        state.data_state.users.state = newState
    }
}