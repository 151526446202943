<template>
    <div>
        <b-form-select
            :options="languages"
            text-field="name"
            value-field="language_id"
            v-model="locale"
        >
        </b-form-select>
    </div>
</template>

<script>
/**
 * This file is part of the GMS package.
 * 
 * @version 1.3
 */

import { /*state, mutations*/ } from '@/store/index.js'
import storage from '@/lib/gms/storage.js'
//import { g11n } from '@/lib/g11n.js'

/**
 * Globalizace (Globalization - G11n) = I18n + L10n.
 */
export default {
    computed: {
        locale: {
            get ( ) {
                let g11n = storage.getItem("g11n")

                // Vrátí locale ze storage, pokud je nastaveno
                if (g11n != null) return g11n.locale

                return this.$i18n.locale
            },
            set (locale) {
                this.setLocale(locale)

                window.location.reload( )
            }
        }
    },

    data( ) {
        return {
            /**
             * Podporované locales
             */
            languages: [
                { language_id: "cs", name: "Čeština" },
                { language_id: "en", name: "English" }
            ]
        }
    },

    methods: {
        /**
         * Vrátí aktuální locale.
         * 
         * 
         * @param {string}  locale (cs, en, ...)
         */
        getLocale: function ( ) {
            let g11n = storage.getItem("g11n")

            // Vrátí locale ze storage, pokud je nastaveno
            if (g11n != null) return g11n.locale

            return this.$i18n.locale
        },

        /**
         * Nastaví aktuální locale.
         * 
         * 
         * @param {string}  locale (cs, en, ...)
         */
        setLocale: function (locale) {
            // Přepne aktuální locale
            this.$i18n.locale = locale

            // Zapíše aktuální locale do storage
            let g11n = {
                locale: locale
            }

            storage.setItem("g11n", g11n)

            //mutations.setLocale(locale)
        }
    },

    mixins: [ ],

    mounted( ) {
        this.setLocale(this.getLocale( ))
    }
}
</script>