import { /*mutations, state*/ } from "@/store"
import storage from '@/lib/gms/storage.js'

export const defaultsG11n = { }

export const stateG11n = {
    /**
     * Data Auth.
     * 
     *  @var {object}
     */
    g11n: {
        /**
         * Locale.
         * 
         *  @var {string}    "en" | "cs" | "en-GB" | "cs-CZ" | ...
         */
        locale: null
    }
}

export const mutationsG11n = {
    /**
     * Vrací locale.
     * 
     * @return {string}
     */

    /* OLD
    getLocale( ) {
        return state.g11n.locale
    },
    */

    getLocale: function ( ) {
        let g11n = storage.getItem("g11n")

        // Vrátí locale ze storage, pokud je nastaveno
        if (g11n != null) return g11n.locale

        return this.$i18n.locale
    },

    /**
     * Nastaví locale.
     * 
     * @param {string} locale
     */
    
    /* OLD
    setLocale(locale) {
        state.g11n.locale = locale
    }
    */
    setLocale: function (locale) {
        // Přepne aktuální locale
        this.$i18n.locale = locale

        // Zapíše aktuální locale do storage
        let g11n = {
            locale: locale
        }

        storage.setItem("g11n", g11n)

        //mutations.setLocale(locale)
    }
}