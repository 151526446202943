import { mutations, state } from '@/store'
import * as constants from '@/store/constants.js'
//import { testData } from '@/store/test_data.js'
import { sendRequest } from '@/lib/gms/api.js'
//import { deepCopy } from '@/lib/utils.js'

import { defaultsRezervaceHost } from '@/store/rezervace_host.js'

export const defaultsRezervace = { }

export const stateRezervace = {
    /**
     * Data rezervace.
     * 
     *  @var {object}
     * 
        {
            "hoste": [
                {
                    "rezervace_host_id":"8",
                    "pokoj_id":"11",
                    "host_kategorie_id":"3",
                    "prijmeni":"Stará ",
                    "jmeno":"Petra",
                    "email":null,
                    "tel":null,
                    "d_narozeni":null,
                    "cislo_dokladu":"206874060",
                    "cislo_viza":null,
                    "zeme_id":"1",
                    "zeme_nazev": "Česká republika",
                    "ulice":"Pod vinicí",
                    "mesto":null,
                    "psc":null,
                    "statni_prislusnost":"1",
                    "ucel_pobytu_id":"1",
                    "poznamka":null
                }
            ],
            "pokoje": [
                {
                    "pokoj_id": "11",
                    "nazev": "Srub S1",
                    "popis": "Luxusní srub s venkovní terasou, čtyřmi ložnicemi a třemi koupelnami.Srub je vybaven plně zařízenou kuchyní, společenskou místností s gaučem, smart televizí, rádiem, wifi připojením.Ve srubu je jedna ložniče s manželskou postelí, vlastní koupelnou, francouzkým oknem s výhledem na ranč, ložnice s manželskou postelí s výhledem na ranč a okolní přírodu, jeden pokoj s dvěmi jednolůžky, jeden čtyřlůžkový pokoj s vlastní koupelnou a obývací místnost se čtyřmi přistýlkami. Všechny koupelny jsou vybaveny vysoušečem vlasů, hygienickou kosmetikou a ručníky. ",
                    "pocet_luzek": "10",
                    "pocet_pristylek": "4",
                    "obsazena_luzka": "0",
                    "volna_luzka": "10",
                    "nenabizet": false
                }

            ],
            "rezervace": {
                "rezervace_id":"9",
                "rezervace_stav_id":"4",
                "cislo":"1619719585",
                "dat_cas_od":"2021-06-04 14:00:00",
                "dat_cas_do":"2021-06-06 10:00:00"
            },
            "zeme": [
                {
                    "nazev":"Česká republika",
                    "zeme_id":1
                }
            ]
        }
     */
    rezervace_data: {
        hoste: [ ],
        pokoje: [ ],
        rezervace: {
            cislo: null,
            dat_cas_od: null,
            dat_cas_do: null,
            rezervace_id: null
        },
        zeme: [ ]
    },

    /**
     * @var {string} Id rezervace z query požadavku
     * NOTICE Bude vždy zaheshované!
     */
    request_rezervace_id: null
}

export const mutationsRezervace = {
    /**
     * Načte data rezervace a pomocná data.
     * 
     * @return {Promise}
     */
    readRezervace(rezervaceIdHash) {
        return new Promise((resolve, reject) => {
            mutations.setDataStateRezervace(constants.DATA_STATE_PREPARING)

            sendRequest(
                '/api/read-reservation',
                {
                    p: rezervaceIdHash
                })
                .then(response => {
                    //console.log(response)
        
                    state.rezervace_data = response.data

                    // Příprava dat
                        // Rezervace - převod na čísla
                    state.rezervace_data.rezervace.deti = parseInt(state.rezervace_data.rezervace.deti)
                    state.rezervace_data.rezervace.dospeli = parseInt(state.rezervace_data.rezervace.dospeli)

                        // Hosté - doplnění prázdných záznamů do celkového počtu
                    let hoste = state.rezervace_data.hoste
                    for (let i = hoste.length; i < (state.rezervace_data.rezervace.deti + state.rezervace_data.rezervace.dospeli); i++) {
                        hoste.push(defaultsRezervaceHost.rezervace_host)
                    }

                    state.rezervace_data.hoste = hoste
                    
                    mutations.setDataStateRezervace(constants.DATA_STATE_READY)

                    resolve(response.data)
                })
                .catch(errors => {
                    //console.log('readPlate.errors', errors)

                    mutations.setDataStateRezervace(constants.DATA_STATE_NOT_READY)

                    reject(errors)
                })
        })
    },

    setRequestRezervaceId(rezervaceIdHash) {
        state.request_rezervace_id = rezervaceIdHash
    }
}