/**
 * Konstanty ve frontend aplikaci.
 */

/**
 * Stav dat.
 */
export const DATA_STATE_NOT_READY   = 'NOT_READY'
export const DATA_STATE_PREPARING   = 'PREPARING'
export const DATA_STATE_READY       = 'READY'

/**
 * Stav rezervace.
 */
 export const REZERVACE_STAV_OPCE       = 1
 export const REZERVACE_STAV_POTVRZENO  = 2
 export const REZERVACE_STAV_CHECK_IN   = 3
 export const REZERVACE_STAV_CHECK_OUT  = 4
 export const REZERVACE_STAV_STORNO     = 5