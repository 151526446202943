/**
 * Store.
 */

import { mutationsAuth, stateAuth } from '@/store/auth.js'
import { mutationsDataState, stateDataState } from '@/store/data_state.js'
import { mutationsError, stateError } from '@/store/error.js'
import { mutationsG11n, stateG11n } from '@/store/g11n.js'
import { mutationsRezervace, stateRezervace } from '@/store/rezervace.js'
import { mutationsRezervaceHost, stateRezervaceHost } from '@/store/rezervace_host.js'
import { mutationsSetting, stateSetting } from '@/store/setting.js'
import { mutationsUser, stateUser } from '@/store/user.js'

import Vue from "vue"

export const mutations = {
    ...mutationsAuth,
    ...mutationsDataState,
    ...mutationsError,
    ...mutationsG11n,
    ...mutationsRezervace,
    ...mutationsRezervaceHost,
    ...mutationsSetting,
    ...mutationsUser
}

export const state = Vue.observable({
    ...stateAuth,
    ...stateDataState,
    ...stateError,
    ...stateG11n,
    ...stateRezervace,
    ...stateRezervaceHost,
    ...stateSetting,
    ...stateUser
})